import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")

  return ((_ctx.$socket.logged.value === true && _ctx.$route.path !== '/login') || (_ctx.$socket.logged.value === false && _ctx.$route.path === '/login')
  )
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath,
        style: {"text-align":"center"}
      }))
    : _createCommentVNode("", true)
}